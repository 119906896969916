<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <!-- 부서별 무재해 정보 -->
        <c-card title="LBL0010314" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <!-- 삭제 -->
              <!-- pjmProjectUninjuryId -> saiDeptUninjuryId로 변경 -->
              <c-btn
                v-if="editable && popupParam.saiDeptUninjuryId"
                label="LBLREMOVE"
                icon="delete_forever"
                @btnClicked="deleteData"/>
              <!-- 저장 -->
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-4">
              <c-plant type="edit" :required="true" :editable="editable && popupParam.saiDeptUninjuryId == '' " v-model="data.plantCd" />
            </div>
            <div class="col-4">
              <!-- 무재해 대상부서 -->
              <c-dept
                type="edit"
                :required="true"
                :isFirstValue="false"
                :editable="editable && popupParam.saiDeptUninjuryId == ''"
                label="LBL0010315"
                name="deptCd"
                :plantCd="data.plantCd"
                v-model="data.deptCd">
              </c-dept>
            </div>
            <div class="col-4">
            </div>
            <div class="col-3">
              <!-- 무재해 시작일 -->
              <c-datepicker
                :editable="editable"
                :required="true"
                label="LBL0010308"
                name="startDt"
                :end="data.targetDt"
                v-model="data.startDt"
                @datachange="selectUninjuryDt"
              />
            </div>
            <div class="col-3">
              <!-- 무재해 목표달성일 -->
              <c-datepicker
                :editable="editable"
                :required="true"
                label="LBL0010310"
                name="targetDt"
                :start="data.startDt"
                v-model="data.targetDt"
                @datachange="selectUninjuryDt"
              />
            </div>
            <div class="col-3">
              <!-- 무재해 일수 -->
              <c-text
                :editable="false"
                prefix="D+"
                :suffix="totalCntLabel"
                label="LBL0010316"
                name="dateCnt"
                type="number"
                v-model="data.dateCnt"
              />
            </div>
            <div class="col-3">
              <c-text
                :editable="false"
                label=""
                name="endFlagName"
                v-model="data.endFlagName"
              />
            </div>
            <div class="col-3">
              <!-- 종료여부 -->
              <c-checkbox
                :editable="editable"
                :isFlag="true"
                label="LBL0010306"
                name="endFlag"
                v-model="data.endFlag"
                @input="changeEnd"
              />
            </div>
            <div class="col-3">
              <!-- 종료사유 -->
              <c-select
                :editable="editable && data.endFlag == 'Y'"
                :required="data.endFlag == 'Y'"
                codeGroupCd="END_REASON_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="endReasonCd"
                label="LBL0010317"
                v-model="data.endReasonCd"
              ></c-select>
            </div>
            <div class="col-3">
              <!-- 종료일 -->
              <c-datepicker
                :editable="editable && data.endFlag == 'Y'"
                :required="data.endFlag == 'Y'"
                label="LBL0010318"
                name="endDt"
                v-model="data.endDt"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'uninjury-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiDeptUninjuryId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      editable: true,
      totalCntLabel: '일',
      saveUrl: transactionConfig.sai.uninjury.insert.url,
      mappingType: 'POST',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        plantCd: null,  // 사업장코드
        saiDeptUninjuryId: '',  // 무재해현황 일련번호
        deptCd: '',  // 부서
        startDt: '',  // 무재해 시작일
        targetDt: '',  // 무재해 목표달성 예정일
        endFlag: 'N',  // 종료여부
        endReasonCd: null,  // 종료사유 코드
        endDt: '',  // 종료일
        dateCnt: '',
        dateTotalCnt: '',
        endFlagName: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch: {},
  methods: {
    init() {
      this.detailUrl = selectConfig.sai.uninjury.get.url;
      this.insertUrl = transactionConfig.sai.uninjury.insert.url;
      this.updateUrl = transactionConfig.sai.uninjury.update.url;
      this.deleteUrl = transactionConfig.sai.uninjury.delete.url;
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
      if (this.popupParam.saiDeptUninjuryId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {saiDeptUninjuryId : this.popupParam.saiDeptUninjuryId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.totalCntLabel = this.$comm.getLangLabel('LBL0010319') + '/' + _result.data.dateTotalCnt + this.$comm.getLangLabel('LBL0010319');
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.totalCntLabel = this.$comm.getLangLabel('LBL0010319');
      }
    },
    changeEnd(_data) {
      if(_data == 'Y') {
        this.data.endDt = this.data.targetDt;
        this.data.endReasonCd = null;
      } else {
        this.data.endDt = '';
        this.data.endReasonCd = null;
      }
    },
    saveData() {
      if (this.popupParam.saiDeptUninjuryId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM', // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.popupParam.saiDeptUninjuryId = result.data.saiDeptUninjuryId
      }
      this.getData();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSG0010025',   // 무재해현황을 삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiDeptUninjuryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    selectUninjuryDt() {
      if (this.data.startDt != '' && this.data.targetDt != '') {
        this.totalCntLabel = this.$comm.getLangLabel('LBL0010319') + ' / ' + this.$comm.getDatediff(this.$comm.moment(this.data.startDt), this.$comm.moment(this.data.targetDt)) + this.$comm.getLangLabel('LBL0010319');
      }
    }
  },
};
</script>